@import './../../../styles/mixins/responsive';

.Logo {
  background-repeat: no-repeat;
  background-size: cover;
  height: 60px;
  width: 238px;

  @include media-breakpoint(sm) {
    height: 64px;
    width: 270px;
  }

  @include media-breakpoint(md) {
    height: 76px;
    width: 300px;
  }
}