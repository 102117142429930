@import './vars/app';

.content {
  width: 100%;
}

.input-group {
  &.has-validation {
    > .form-control {
      border-top-right-radius: 0.2rem;
      border-bottom-right-radius: 0.2rem;
    }
  }
}

.bg-success {
  background-color: $success !important;
}

.bg-warning {
  background-color: $warning !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

_:-ms-fullscreen,
:root {
  .content {
    flex: auto;
  }

  .footer {
    flex-basis: auto;
  }
}

.routeFadeIn {
  animation: a-routeFadeIn 0.5s;
  animation-fill-mode: both;

  &.fade-exit {
    animation: none;
    animation-fill-mode: none;
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
  }
}

@keyframes a-routeFadeIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
    height: 0;
    overflow: hidden;
  }
  0.01% {
    height: auto;
    overflow: visible;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
